body {
    --light-base:    #fafafa;
    --light-mono-1:  #383a42;
    --light-mono-2:  #686b77;
    --light-mono-3:  #a0a1a7;
    --light-hue-1:   #0184bb;
    --light-hue-2:   #4078f2;
    --light-hue-3:   #a626a4;
    --light-hue-4:   #50a14f;
    --light-hue-5:   #e45649;
    --light-hue-5-2: #c91243;
    --light-hue-6:   #986801;
    --light-hue-6-2: #c18401;

    --dark-base:    #282c34;
    --dark-mono-1:  #abb2bf;
    --dark-mono-2:  #818896;
    --dark-mono-3:  #5c6370;
    --dark-hue-1:   #56b6c2;
    --dark-hue-2:   #61aeee;
    --dark-hue-3:   #c678dd;
    --dark-hue-4:   #98c379;
    --dark-hue-5:   #e06c75;
    --dark-hue-5-2: #be5046;
    --dark-hue-6:   #d19a66;
    --dark-hue-6-2: #e6c07b;
}

.code-container {
    font-family: "Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    tab-size: 2;
    hyphens: none;
    padding: 1em 0;
    border-radius: 0.3em;
    transition: color 300ms, background 300ms;
}

.code-container .comment,
.code-container .operator,
.code-container .builtin,
.code-container .decorator,
.code-container .decorator.punctuation,
.code-container .function,
.code-container .punctuation,
.code-container .boolean,
.code-container .number,
.code-container .keyword,
.code-container .class-name {
    transition: color 300ms;
}

.code-container .last-of-line {
    padding-right: 1em;
}

.code-container > code {
    white-space: pre;
}

.code-container .linenumber {
    font-style: italic;
}

/* Dark Theme */

.article pre {
    background: var(--dark-base);
}

.code-container {
    background: var(--dark-base);
    color: var(--dark-mono-1);
}

.code-container .comment {
    color: var(--dark-mono-3);
}

.code-container .operator,
.code-container .builtin {
    color: var(--dark-hue-1);
}

.code-container .decorator,
.code-container .decorator.punctuation,
.code-container .function {
    color: var(--dark-hue-2);
}

.code-container .keyword {
    color: var(--dark-hue-3);
}

.code-container .string {
    color: var(--dark-hue-4);
}

.code-container .punctuation,
.code-container .boolean,
.code-container .number {
    color: var(--dark-hue-6);
}

.code-container .class-name {
    color: var(--dark-hue-6-2);
}

/* Light Theme */

.light .article pre {
    background: var(--light-base);
}

.light .code-container {
    background: var(--light-base);
    color: var(--light-mono-1);
}

.light .code-container.light .comment {
    color: var(--light-mono-3);
}

.light .code-container .operator,
.light .code-container .builtin {
    color: var(--light-hue-1);
}

.light .code-container .decorator,
.light .code-container .decorator.punctuation,
.light .code-container .function {
    color: var(--light-hue-2);
}

.light .code-container .keyword {
    color: var(--light-hue-3);
}

.light .code-container .string {
    color: var(--light-hue-4);
}

.light .code-container .punctuation,
.light .code-container .boolean,
.light .code-container .number {
    color: var(--light-hue-6);
}

.light .code-container .class-name {
    color: var(--light-hue-6-2);
}