@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/default.min.css');

body {
  /* Colours */
  --baby-blue-eyes: #B1CFFB;
  --charcoal: #4A5160;
  --cultured: #ECECEC;
  --fire-engine-red: #CF2B2B;
  --flickr-blue: #0C65EB;
  --light-grey: #C6C9CB;
  --slate-grey: #767B85;
  --raisin-black: #22222E;
  --roman-silver: #8A8F9A;
  --platinum: #D2D7DA;
  --space-cadet: #20335E;
  --gunmetal: #2A3244;
  --white: #FFFFFF;
  --abril-fatface: 'Abril Fatface';
  --lato: 'Lato';

  /*
    SVG Colour Filters
    https://codepen.io/sosuke/pen/Pjoqqp
  */
  --svg-flickr-blue: invert(24%) sepia(90%) saturate(2438%) hue-rotate(211deg) brightness(95%) contrast(95%);
  --svg-white: invert(100%) sepia(100%) saturate(0%) hue-rotate(65deg) brightness(106%) contrast(102%);

  /* Font Sizes */
  --msg-size: calc(14px + 1vmin);
  --page-width: 768px;

  /* Theme */
  --background: var(--raisin-black);
  --foreground: var(--white);
  --backgroundAccent: var(--gunmetal);
  --darkGrey: var(--charcoal);
  --lightGrey: var(--light-grey);
  --primary: var(--flickr-blue);
  --primary-contrast: var(--white);
  --primary-light: var(--baby-blue-eyes);
  --svg-primary: var(--svg-flickr-blue);
  --svg-primary-contrast: var(--svg-white);
  --ripple: var(--space-cadet);
  --title-font: var(--abril-fatface);
  --body-font: var(--lato);
  --blog-font: var(--lato);
  --error: var(--fire-engine-red);
  --disabled: var(--roman-silver);

  --size1: 8px;
  --size2: 16px;
  --size3: 24px;
  --size4: 32px;
  --size5: 40px;
}

/* Theme changes */
body.light {
  --background: var(--cultured);
  --backgroundAccent: var(--platinum);
  --foreground: var(--raisin-black);
  --darkGrey: var(--light-grey);
  --lightGrey: var(--charcoal);
  --ripple: var(--baby-blue-eyes);
  --disabled: var(--slate-grey);
} 

/* Remove annoying Android green hitbox */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Default Style */
a {
  -webkit-tap-highlight-color: transparent;
  color: var(--foreground);
  transition: color 300ms;
}

body {
  margin: 0;
  background: var(--background);
  color: var(--foreground);
  text-align: left;
  font-family: var(--body-font), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 300ms, background 300ms;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--page-width);
  margin: auto;
  margin-top: var(--size3);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size3);
}

.header-pad {
  padding-top: 80px;
}

.logo-text, .logo-text .h1 {
  font-size: var(--size3);
  text-decoration: none;
}

.a-center {
  align-items: center;
}

.m1 {margin: var(--size1);}
.m2 {margin: var(--size2);}
.m3 {margin: var(--size3);}
.ml1 {margin-left: var(--size1);}
.ml2 {margin-left: var(--size2);}
.ml3 {margin-left: var(--size3);}
.mlauto {margin-left: auto;}
.mr1 {margin-right: var(--size1);}
.mr2 {margin-right: var(--size2);}
.mr3 {margin-right: var(--size3);}
.mrauto {margin-right: auto;}
.mb1 {margin-bottom: var(--size1);}
.mb2 {margin-bottom: var(--size2);}
.mb3 {margin-bottom: var(--size3);}
.mt1 {margin-top: var(--size1);}
.mt2 {margin-top: var(--size2);}
.mt3 {margin-top: var(--size3);}

.p1 {padding: var(--size1);}
.p2 {padding: var(--size2);}
.p3 {padding: var(--size3);}
.pl1 {padding-left: var(--size1);}
.pl2 {padding-left: var(--size2);}
.pl3 {padding-left: var(--size3);}
.pr1 {padding-right: var(--size1);}
.pr2 {padding-right: var(--size2);}
.pr3 {padding-right: var(--size3);}
.pb1 {padding-bottom: var(--size1);}
.pb2 {padding-bottom: var(--size2);}
.pb3 {padding-bottom: var(--size3);}
.pt1 {padding-top: var(--size1);}
.pt2 {padding-top: var(--size2);}
.pt3 {padding-top: var(--size3);}

.px2 {
  padding-left: var(--size2);
  padding-right: var(--size2);
}

.full-width {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.no-select {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.logo {
  height: 40vmin;
  pointer-events: none;
  filter: var(--svg-primary-contrast);
}

.tagline {
  margin-top: var(--size1);
  font-weight: lighter;
  font-size: 1.2em;
  text-align: center;
}

.title {
  font-size: 2.4em;
}

.h1 {
  font-size: 1.8em;
}

.left-header {
  font-size: 1.8em;
  font-family: var(--title-font);
  margin-right: auto;
}

.countdown .days {
  font-size: 48px;
}

.countdown .phrase {
  font-size: 20px;
  text-align: center;
  width: 350px;
}

.countdown img {
  height: 350px;
  width: 350px;
}

/* Desktop Style */
@media
  only screen and (min-width: 1000px)
{
  .countdown .days {
    font-size: 64px;
  }

  .countdown img {
    height: 1024px;
    width: 1024px;
  }

  .countdown .phrase {
    font-size: 36px;
    width: 1024px;
  }

  .article pre {
    max-width: var(--page-width);
    max-height: 500px;
  }
}

.title, .h1 {
  font-family: var(--title-font);
}

.primary {
  background: var(--primary);
  color: var(--primary-contrast);
}

.big-margin {
  margin-top: 80px;
  margin-bottom: 56px;
}

.disabled-color {
  color: var(--disabled);
}

.divider {
  width: 100%;
  margin-top: var(--size2);
  margin-bottom: var(--size2);
  border-top: 2px solid var(--primary);
}

.header {
  background-color: var(--primary);
  color: var(--primary-contrast);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1;
}

.header a {
  color: var(--primary-contrast);
}

.header .icon, .header .link {
  display: flex;
  align-items: center;
  margin-right: var(--size2);
  color: var(--primary-light);
  transition: color 300ms;
}

.header .icon {
  width: 20px;
}

.header .row-container {
  display: flex;
  justify-content: space-between;
  margin: var(--size1);
}

.header .logo {
  height: 60px;
}

.header .logo-parent {
  display: block;
}

.header .link {
  font-size: var(--size2);
  margin-right: var(--size3);
  text-decoration: none;
}

.header .link:hover, .header .icon:hover {
  color: var(--primary-contrast);
}

.header .link:first-child {
  margin-left: var(--size2);
}

.header .link.selected {
  font-weight: bold;
  color: var(--primary-contrast);
}

.row .content {
  display: flex;
  align-items: center;
  width: 100%;
}

.row .content .rowTitle {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 8px;
}

.row img {
  width: 70px;
}

.row .toolbar {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.gallery .row {
  cursor: pointer;
  transition: background-color 300ms;
}

.gallery .row:hover {
  background: var(--ripple);
}

.gallery .reading-time,
.gallery .publish-date {
  margin-right: var(--size1);
}

.gallery .row .text {
  width: 100%;
}

.gallery .desc {
  display: flex;
  justify-content: space-between;
  color: var(--disabled);
}

.gallery .link {
  margin-right: var(--size3);
}

/* Mobile Style */
@media
  only screen and (max-width: 1000px)
{
  .left-header {
    margin-left: var(--size3);
  }

  .gallery {
    width: 100%;
  }

  .gallery > div:first-child {
    border-top: 1px solid var(--darkGrey);
    transition: border-top 300ms;
  }
  
  .gallery .row {
    align-items: center;
    height: 120px;
    border-bottom: 1px solid var(--darkGrey);
    transition: border-bottom 300ms;
  }
  
  .gallery .row .content {
    height: 100%;
  }
  
  .gallery .row:active {
    background: var(--ripple);
  }
  
  .gallery .text {
    padding-left: var(--size3);
    padding-right: var(--size1);
  }
  
  .gallery .toolbar {
    height: calc(100% - 0px);
  }
  
  .gallery .row img {
    margin-left: var(--size2);
  }

  .blog.gallery .desc {
    margin-top: var(--size2);
  }

  .gallery .link {
    display: none;
  }
}

/* Desktop Style */
@media
  only screen and (min-width: 1000px)
{
  .gallery {
    width: var(--page-width);
    margin-bottom: var(--size3);
  }
  
  .gallery img {
    padding: var(--size2);
  }

  .gallery .toolbar .icon {
    color: var(--primary-contrast);
  }
  
  .gallery .row {
    margin-top: var(--size2);
    border-radius: var(--size2);
  }
  
  .gallery .row:first-child {
    margin-top: 0px;
  }

  .gallery .row {
    background-color: var(--backgroundAccent);
  }

  .gallery .content {
    border-radius: 15px;
    border: none;
  }
  
  .gallery .row .text {
    padding: var(--size2);
  }
  
  .gallery .toolbar {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background: var(--primary);
  }
}

.bold {
  font-weight: bold;
}

.error-box {
  background-color: var(--error);
  padding: var(--size3);
  border-radius: var(--size1);
  font-size: var(--size3);
}

.article.blog {
  font-family: var(--blog-font);
}

.article.blog p, .article.blog li {
  font-size: large;
  line-height: 28px;
}

.article mjx-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.article img + em,
.article mjx-container + em {
  display: block;
  text-align: center;
  font-size: medium;
}

.article blockquote {
  color: var(--disabled);
  font-style: italic;
  box-shadow: inset 3px 0 0 0 var(--disabled);
  margin-left: 0px;
  padding-left: 20px;
}

.article pre {
  overflow: auto;
  font-size: small;  
}

.article .has-image {
  text-align: center;
}

.article p, .article li {
  
  line-height: var(--size3);
}

.article h1 {
  font-size: 1.6em;
  margin-top: var(--size4);
  margin-bottom: var(--size4);
}

.article h2 {
  font-size: 1.4em;
  margin-top: var(--size3);
  margin-bottom: var(--size3);
  font-style: italic;
}

.article h3 {
  font-size: 1.2em;
  margin-top: var(--size3);
  margin-bottom: var(--size3);
  font-style: italic;
  color: var(--light-grey);
}

.article blockquote {
  color: var(--lightGrey);
}

.article-parent .h1 {
  font-size: 2em;
  margin-top: var(--size1);
}

.article-parent .h1.no-byline {
  padding-bottom: var(--size2);
  border-bottom: 2px solid var(--primary);
}

.article-parent .byline {
  display: flex;
  justify-content: space-between;
  margin-top: var(--size2);
  padding-bottom: var(--size2);
  border-bottom: 2px solid var(--primary);
}

.article-parent .byline .author {
  font-weight: bold;
  margin-right: var(--size2);
}

.article-parent .byline .reading-time,
.article-parent .byline .publish-date {
  color: var(--disabled);
}

.article-parent .byline > span:not(:first-child) {
  margin-left: var(--size4);
}

.article-parent .article {
  margin-top: var(--size4);
}

.article .img-container {
  text-align: center;
}

.article .img-container img {
  max-width: 100%;
}

/* Styles on mobile */
@media
  only screen and (max-width: 1000px)
{
  .container {
    max-width: 100%;
  }

  .article-parent {
    width: calc(100% - 48px);
    margin-left: var(--size3);
    margin-right: var(--size3);
    margin-bottom: var(--size3);
  }

  .logo-text {
    display: none;
  }

  .big-margin {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  .article pre {
    max-height: 400px;
  }
}